import gql from 'graphql-tag'


export const GET_CANDIDATE_NOTIFY = gql`
  query getCandidateNotify($candidateId: ID!) {
   getCandidateNotify(candidateId: $candidateId) {
    candidate{
      name
    }
    content{
      idType
      type
      view
      entityName
      description
    }
    }
  }
`
export const GET_ENTITY_LIST = gql`
  query entities{
    entities{
      id
      name
      logo
    }
  }
`