import gql from 'graphql-tag'

export const  NOTIFY_CANDIDATE = gql `
subscription candidateNotify{
  candidateNotify{
    candidate{
      name
    }
    content{
      idType
      type
      view
    }
  }
}
`