<template>
  <div>
    <app-menu v-model="drawer" />
    <app-toolbar v-model="drawer" />

    <v-container>
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppMenu from '../components/AppMenu.vue'
import AppToolbar from '../components/AppToolbar.vue'
export default {
  name: 'AppLayout',
  components: {
    AppMenu,
    AppToolbar
  },
  data: () => ({
    drawer: true
  })
}
</script>